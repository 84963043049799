.Toastify__toast-container {
  width: 26rem;
  color: #000 !important;
}

.Toastify__toast {
  border-radius: 0.5rem;
  min-height: 48px;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast-icon {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 3rem;
}

.Toastify__toast-icon > svg {
  height: 1rem;
  width: 1rem;
}

.Toastify__close-button {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 3rem;
}

.Toastify__toast--success {
  border: 1px solid #29cb72;
  background: #e9f7eb;
}

.Toastify__toast--error {
  border: 1px solid #f72c40;
  background: #ffeaee;
}
